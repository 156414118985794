import React, { Fragment } from "react";
import moment from "moment";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import Decimal from "decimal.js-light";
import { formatCurrency } from "../../../utils/numberFormat";
import EstimateStatusLabel from "../../estimate/EstimateStatusLabel";
import DocumentDivisionDetailsDialog from "../../documentDivision/DocumentDivisionDetailsDialog";

const ITEMS_PER_PAGE = 15;
const CURRENT_PAGE = 0;

function DividedEstimatesList({ estimates, focusedUser, hasDivision }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Numéro / Objet / Utilisateur</TableCell>
          <TableCell>Client</TableCell>
          {hasDivision && <TableCell>Montant HT réparti</TableCell>}
          <TableCell>Montant HT{hasDivision && " total"}</TableCell>
          <TableCell>Statut</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estimates.length > 0 ? (
          estimates
            .slice(CURRENT_PAGE * ITEMS_PER_PAGE, CURRENT_PAGE * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
            .map(estimate => {
              let rateAmount = new Decimal(estimate.totalPriceWithoutTVA);
              let userRate = 100;
              if (estimate.divisions && estimate.divisions.length > 0) {
                const userDivision = estimate.divisions.find(division => division.user.id === focusedUser.id);
                if (userDivision) {
                  userRate = userDivision.rate;
                  rateAmount = new Decimal(estimate.totalPriceWithoutTVA)
                    .mul(userRate)
                    .div(100)
                    .toNumber();
                }
              }
              return (
                <TableRow key={estimate.id}>
                  <TableCell>{moment(estimate.createAt).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>
                    N°{estimate.number}
                    <br />
                    <span style={{ fontWeight: "bold" }}>{estimate.nature}</span>
                    <br />
                    {estimate.user && `par ${estimate.user.firstname} ${estimate.user.lastname.toUpperCase()}`}
                    {estimate.divisions && estimate.divisions.length > 0 && (
                      <Fragment>
                        <br />
                        <DocumentDivisionDetailsDialog
                          divisions={estimate.divisions}
                          title={`Vente partagée pour le devis ${estimate.number}`}
                          currentUser={focusedUser}
                        />
                      </Fragment>
                    )}
                  </TableCell>
                  <TableCell>
                    {estimate.contact.businessName}
                    {estimate.contact.businessName && <br />}
                    {`${estimate.contact.businessName && "("}${
                      estimate.contact.firstname
                    } ${estimate.contact.lastname.toUpperCase()}${estimate.contact.businessName && ")"}`}
                  </TableCell>
                  {hasDivision && (
                    <TableCell>
                      {formatCurrency(rateAmount, estimate.currency)}
                      {userRate < 100 ? ` (${userRate}%)` : ""}
                    </TableCell>
                  )}
                  <TableCell>{formatCurrency(estimate.totalPriceWithoutTVA, estimate.currency)}</TableCell>
                  <TableCell>
                    <EstimateStatusLabel estimate={estimate} />
                  </TableCell>
                </TableRow>
              );
            })
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Aucun devis établi sur la période
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DividedEstimatesList;
