import React, { Fragment, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  makeStyles,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box
} from "@material-ui/core";
import { PictureAsPdf, Visibility, GetApp } from "@material-ui/icons"; // Email
import { inject, observer } from "mobx-react";
import fileDownload from "js-file-download";
import PreviewAssetsFileDialog from "./PreviewAssetsFileDialog";
import AssetsService from "../../services/AssetsService";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: 0
  },
  fileName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem"
    }
  },
  card: {
    height: 140,
    cursor: "pointer",
    "& #preview-card": {
      display: "none"
    },
    "&:hover #preview-card": {
      display: "flex"
    },
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
    }
  },
  noFiles: {
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
    textAlign: "center",
    padding: theme.spacing(2)
  }
}));

function FilesList({ files, displayMode = "LIST", sessionStore }) {
  const classes = useStyles();
  const [openPreview, setOpenPreview] = useState(false);
  const [focusedFile, setFocusedFile] = useState(null);

  if (!files) return null;

  if (files.length === 0)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body1" component="p" className={classes.noFiles}>
          Aucun fichier
        </Typography>
      </Box>
    );

  const _files = files.sort((a, b) => a.name.localeCompare(b.name));

  const handlePreview = (file, e) => {
    if (e) {
      e.stopPropagation();
    }
    setOpenPreview(true);
    setFocusedFile(file);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setFocusedFile(null);
  };

  const handleDownload = (file, e) => {
    e.stopPropagation();
    AssetsService.downloadFile(file.id).then(res => {
      fileDownload(res, `${file.name}${file.extension}`);
    });
  };

  // const handleEmail = file => {
  //   console.log("Email file:", file.id);
  // };

  return (
    <Fragment>
      <PreviewAssetsFileDialog
        file={focusedFile}
        open={openPreview}
        onClose={handleClosePreview}
        handleDownload={handleDownload}
      />
      {displayMode === "LIST" ? (
        <List className={classes.root}>
          {_files && _files.length > 0 ? (
            _files.map((file, index) => (
              <ListItem key={file.id} divider={index !== files.length - 1} button onClick={() => handlePreview(file)}>
                <ListItemIcon>
                  <PictureAsPdf color="primary" />
                </ListItemIcon>
                <ListItemText primary={<Typography className={classes.fileName}>{file.name}</Typography>} />
                <ListItemSecondaryAction>
                  {/* <IconButton edge="end" aria-label="preview" onClick={e => handlePreview(file, e)}>
                    <Visibility />
                  </IconButton> */}
                  <IconButton edge="end" aria-label="download" onClick={e => handleDownload(file, e)}>
                    <GetApp />
                  </IconButton>
                  {/* <IconButton edge="end" aria-label="email" onClick={() => handleEmail(file)}>
                    <Email />
                  </IconButton> */}
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="Aucun fichier trouvé" />
            </ListItem>
          )}
        </List>
      ) : (
        <Grid container spacing={2}>
          {_files && _files.length > 0 ? (
            _files.map(file => (
              <Grid item xs={12} sm={6} md={4} key={file.id}>
                <Card>
                  <CardMedia
                    image={`${process.env.API_URL}/public/assets/${sessionStore.user.company.id}/${file.samplePath}`}
                    // title={file.name}
                    className={classes.card}
                    onClick={() => handlePreview(file)}
                  >
                    <Box
                      id="preview-card"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      flexDirection="column"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", gap: 8, color: "white" }}
                    >
                      <Visibility />
                      <Typography variant="body1">Voir</Typography>
                    </Box>
                  </CardMedia>
                  <CardContent>
                    <Typography variant="h6">{file.name}</Typography>
                  </CardContent>
                  <CardActions style={{ justifyContent: "space-between" }}>
                    <Button size="small" color="primary" startIcon={<GetApp />} onClick={() => handleDownload(file)}>
                      Télécharger
                    </Button>
                    {/* <Button
                      size="small"
                      color="primary"
                      startIcon={<Email />}
                      onClick={() => handleEmail(file)}
                      variant="contained"
                    >
                      Envoyer par email
                    </Button> */}
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6">Aucun fichier trouvé</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
}

export default inject("sessionStore")(observer(props => <FilesList {...props} />));
