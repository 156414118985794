import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { Fragment, useMemo, useState } from "react";
import DocumentDivisionRow from "./DocumentDivisionRow";

export default function DocumentDivisionDetailsDialog({ divisions, title, currentUser }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sortedDivisions = useMemo(() => {
    return divisions.sort((a, b) => b.rate - a.rate);
  }, [divisions]);

  if (sortedDivisions.length === 0) {
    return null;
  }

  if (sortedDivisions.length === 1 && sortedDivisions[0].user.id === currentUser.id) {
    return null;
  }

  return (
    <Fragment>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={handleOpen}
      >
        Vente partagée
      </Typography>
      <Dialog modal="false" open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>
            {sortedDivisions.map(division => (
              <DocumentDivisionRow key={division.id} documentDivision={division} currentUser={currentUser} />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
