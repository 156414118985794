import React, { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import useQueryParams from "../../hooks/useQueryParams";
import AssetsService from "../../services/AssetsService";
import SubFoldersList from "./SubFoldersList";
import FilesList from "./FilesList";

export default function AssetsFolderViewer({ displayMode = "LIST" }) {
  const queryParams = useQueryParams();

  const folderId = queryParams.get("folderId");

  const { data: assetsFolder, isLoading, error } = useQuery({
    queryKey: ["assets_folders", { folderId }],
    queryFn: () => AssetsService.getAssetsFolder(folderId),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 5
  });

  return (
    <div>
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {error && <div>Error: {error.message}</div>}
      {assetsFolder && (
        <Fragment>
          <Typography variant="h6">{assetsFolder.name}</Typography>
          {assetsFolder.parent ? <Typography variant="subtitle1">Accéder au dossier parent</Typography> : null}
          <SubFoldersList subFolders={assetsFolder.subFolders} />
          <Card>
            <CardContent style={{ padding: 8 }}>
              <FilesList displayMode={displayMode} files={assetsFolder.assetFiles} />
            </CardContent>
          </Card>
        </Fragment>
      )}
    </div>
  );
}
