import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";
import { Folder, OpenInNew } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: 0
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  folderName: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem"
    }
  }
}));

export default function SubFoldersList({ subFolders }) {
  const classes = useStyles();

  if (!subFolders || subFolders.length === 0) return null;

  // const handleOpenFolder = folderId => {
  //   console.log("Open folder:", folderId);
  // };

  return (
    <List className={classes.root}>
      {subFolders.map((subFolder, index) => (
        <ListItem
          key={subFolder.id}
          className={classes.listItem}
          // button
          // onClick={() => handleOpenFolder(subFolder.id)}
          divider={index !== subFolders.length - 1}
        >
          <ListItemIcon>
            <Folder color="primary" />
          </ListItemIcon>
          <ListItemText primary={<Typography className={classes.folderName}>{subFolder.name}</Typography>} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="open folder" onClick={() => handleOpenFolder(subFolder.id)}>
              <OpenInNew />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
