import request, { METHOD } from "../utils/request";

const getAssetsFolder = async folderId =>
  request({
    url: folderId ? `/assets/folders/${folderId}` : "/assets/folders",
    method: METHOD.GET
  });

const downloadFile = async fileId =>
  request({
    url: `/assets/${fileId}/download`,
    method: METHOD.GET,
    responseType: "blob"
  });

export default {
  getAssetsFolder,
  downloadFile
};
