import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(() => ({
  card: { height: "100%" }
}));

export default function CompanyAssetsFileUpload({ company }) {
  const classes = useStyles();
  const [selectedFolder, setSelectedFolder] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [filename, setFilename] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const { data: assetsFolders } = useQuery({
    queryKey: ["companies_assets_folders", { companyId: company.id }],
    queryFn: () => AdminService.getCompanyAssetsFolders(company.id)
  });

  const handleFileUpload = event => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    AdminService.uploadCompanyAsset(company.id, selectedFolder, { file, filename })
      .then(() => {
        enqueueSnackbar("Fichier uploadé avec succès", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de l'upload du fichier", { variant: "error" });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Bibliothèque
            </Typography>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Box>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Typography variant="body2" gutterBottom>
                    Dossier
                  </Typography>
                  {assetsFolders ? (
                    <Select
                      fullWidth
                      value={selectedFolder}
                      onChange={e => setSelectedFolder(e.target.value)}
                      variant="outlined"
                      style={{ textAlign: "left" }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em> -- Sélectionner un dossier --</em>
                      </MenuItem>
                      {assetsFolders.map(folder => (
                        <MenuItem key={folder.id} value={folder.id}>
                          {folder.parent === null ? <strong>Dossier racine</strong> : folder.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </FormControl>
              </Box>
              <Box>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Typography variant="body2" gutterBottom>
                    Nom du fichier
                  </Typography>
                  <TextField
                    value={filename}
                    onChange={e => setFilename(e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Typography variant="body2" gutterBottom>
                    Fichier
                  </Typography>
                  <input
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="uploaded-file"
                    type="file"
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="uploaded-file">
                    <Button
                      fullWidth
                      variant={file ? "outlined" : "contained"}
                      color="primary"
                      component="span"
                      style={{ textAlign: "center" }}
                    >
                      {file ? `Remplacer ${file.name}` : "Importer un fichier"}
                    </Button>
                  </label>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!file || !filename || !selectedFolder}
            >
              Uploader
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
