import { AppBar, Button, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

import { inject, observer } from "mobx-react";
import { TransitionUp } from "../utils/transitions/TransitionUp";
import { isTouchScreenDevice } from "../../utils/app";

const isMobile = isTouchScreenDevice();

function PreviewAssetsFileDialog({ file, open, onClose, handleDownload, sessionStore }) {
  const onDownload = () => {
    if (file) {
      handleDownload(file);
    }
  };

  return (
    <Dialog fullScreen open={open && !!file} onClose={onClose} TransitionComponent={TransitionUp}>
      <AppBar style={{ position: "static" }}>
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" style={{ paddingLeft: 8, flex: 1 }}>
            {file ? file.name : ""}
          </Typography>
          {!isMobile && (
            <Button color="inherit" onClick={onDownload}>
              Télécharger
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isMobile ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Typography style={{ marginTop: 16, marginBottom: 16 }} variant="body2">
            La prévisualisation du devis n&apos;est pas disponible
          </Typography>
          <Button color="primary" variant="outlined" onClick={handleDownload}>
            Télécharger
          </Button>
        </div>
      ) : file ? (
        <iframe
          src={`${process.env.API_URL}/public/assets/${sessionStore.user.company.id}/${file.path}`}
          style={{ width: "100%", height: "100%" }}
        />
      ) : null}
    </Dialog>
  );
}

export default inject("sessionStore")(observer(props => <PreviewAssetsFileDialog {...props} />));
