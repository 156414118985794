import React, { Fragment } from "react";
import moment from "moment";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import Decimal from "decimal.js-light";
import { formatCurrency } from "../../../utils/numberFormat";
import OrderFormStatusLabel from "../../orderForm/OrderFormStatusLabel";
import DocumentDivisionDetailsDialog from "../../documentDivision/DocumentDivisionDetailsDialog";

const ITEMS_PER_PAGE = 15;
const CURRENT_PAGE = 0;

function DividedOrderFormsList({ orders, focusedUser, hasDivision }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Numéro / Objet / Utilisateur</TableCell>
          <TableCell>Client</TableCell>
          {hasDivision && <TableCell>Montant HT réparti</TableCell>}
          <TableCell>Montant HT{hasDivision && " total"}</TableCell>
          <TableCell>Statut</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.length > 0 ? (
          orders.slice(CURRENT_PAGE * ITEMS_PER_PAGE, CURRENT_PAGE * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map(order => {
            let rateAmount = new Decimal(order.totalPriceWithoutTVA);
            let userRate = 100;
            if (order.divisions && order.divisions.length > 0) {
              const userDivision = order.divisions.find(division => division.user.id === focusedUser.id);
              if (userDivision) {
                userRate = userDivision.rate;
                rateAmount = new Decimal(order.totalPriceWithoutTVA)
                  .mul(userRate)
                  .div(100)
                  .toNumber();
              }
            }
            return (
              <TableRow key={order.id}>
                <TableCell>{moment(order.createAt).format("DD/MM/YYYY")}</TableCell>
                <TableCell>
                  N°{order.number}
                  <br />
                  <span style={{ fontWeight: "bold" }}>{order.nature}</span>
                  <br />
                  {order.user && `par ${order.user.firstname} ${order.user.lastname.toUpperCase()}`}
                  {order.divisions && order.divisions.length > 0 && (
                    <Fragment>
                      <br />
                      <DocumentDivisionDetailsDialog
                        divisions={order.divisions}
                        title={`Vente partagée pour le bon de commande ${order.number}`}
                        currentUser={focusedUser}
                      />
                    </Fragment>
                  )}
                </TableCell>
                <TableCell>
                  {order.clientBusinessName}
                  {order.clientBusinessName && <br />}
                  {`${order.clientBusinessName && "("}${
                    order.clientFirstname
                  } ${order.clientLastname.toUpperCase()}${order.clientBusinessName && ")"}`}
                </TableCell>

                {hasDivision && (
                  <TableCell>
                    {formatCurrency(rateAmount, order.currency)}
                    {userRate < 100 ? ` (${userRate}%)` : ""}
                  </TableCell>
                )}
                <TableCell>{formatCurrency(order.totalPriceWithoutTVA, order.currency)}</TableCell>
                <TableCell>
                  <OrderFormStatusLabel orderForm={order} />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              Aucun bon de commande établi sur la période
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DividedOrderFormsList;
