import moment from "moment";
import request, { METHOD } from "../utils/request";

class DashboardService {
  static dashboardOrders(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    contactId = ""
  ) {
    return request({
      url: `/dashboard/orders?from=${from}&to=${to}&contactId=${contactId}`,
      method: METHOD.GET
    });
  }

  static dashboardInvoices(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    contactId = ""
  ) {
    return request({
      url: `/dashboard/invoices?from=${from}&to=${to}&contactId=${contactId}`,
      method: METHOD.GET
    });
  }

  static dashboardEstimates(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    contactId = ""
  ) {
    return request({
      url: `/dashboard/estimates?from=${from}&to=${to}&contactId=${contactId}`,
      method: METHOD.GET
    });
  }

  static userDashboardOrders(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    userId
  ) {
    return request({
      url: `/dashboard/users/${userId}/orders?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static userDashboardEstimates(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    userId
  ) {
    return request({
      url: `/dashboard/users/${userId}/estimates?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static userDashboardInvoices(
    from = moment()
      .startOf("month")
      .format("YYYY-MM"),
    to = moment()
      .endOf("month")
      .format("YYYY-MM"),
    userId
  ) {
    return request({
      url: `/dashboard/users/${userId}/invoices?from=${from}&to=${to}`,
      method: METHOD.GET
    });
  }

  static dashboardPlan(periodFrom = moment().subtract(5, "months"), periodTo = moment()) {
    return request({
      url: `/dashboard/plan?periodFrom=${moment(periodFrom).format("YYYY-MM")}&periodTo=${moment(periodTo).format(
        "YYYY-MM"
      )}`,
      method: METHOD.GET
    });
  }
}

export default DashboardService;
