import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Typography, withStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import AppsIcon from "@material-ui/icons/Apps";
import ViewListIcon from "@material-ui/icons/ViewList";
import NotFoundPage from "./NotFoundPage";
import AssetsFolderViewer from "../components/assets/FolderViewer";

@inject("sessionStore")
@observer
class AssetsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { displayMode: "LIST" };
  }

  render() {
    const { sessionStore, classes } = this.props;
    const { displayMode } = this.state;

    if (!sessionStore.userHasAccess({ requiredFeatures: ["OPTION_ASSETS"] })) return <NotFoundPage />;

    const onDisplayModeChange = (event, value) => {
      this.setState({ displayMode: value });
    };

    return (
      <Fragment>
        <div className={classes.head}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Bibliothèque
          </Typography>
          <ToggleButtonGroup exclusive value={displayMode} onChange={onDisplayModeChange} size="small" color="primary">
            <ToggleButton value="LIST">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="GRID">
              <AppsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <AssetsFolderViewer displayMode={displayMode} />
      </Fragment>
    );
  }
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    alignItems: "center"
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing()
  }
});

export default withStyles(styles)(AssetsPage);
