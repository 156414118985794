import React, { Fragment, useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import BusinessIcon from "@material-ui/icons/Business";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PeopleIcon from "@material-ui/icons/People";
import { makeStyles } from "@material-ui/styles";

import AdminService from "../../services/AdminService";
import CompanyInformations from "./company/CompanyInformations";
import CompanyFeatures from "./company/CompanyFeatures";
import CompanyUsers from "./company/CompanyUsers";
import CompanySignature from "./company/CompanySignature";
import CompanyDocument from "./company/CompanyDocument";
import CompanyFinancing from "./company/CompanyFinancing";
import CompanyAllowances from "./company/CompanyAllowances";
import CompanyMentions from "./company/companyMentions";
import CompanyMailConfiguration from "./company/CompanyMailConfiguration";
import CompanyGlobalConfiguration from "./company/CompanyGlobalConfiguration";
import CompanyAssetsFileUpload from "./company/CompanyAssetsFileUpload";

const MENU = [
  { label: "Informations", key: "INFORMATIONS", icon: <BusinessIcon /> },
  { label: "Configuration", key: "CONFIGURATION", icon: <AccountTreeIcon /> },
  { label: "Utilisateurs", key: "USERS", icon: <PeopleIcon /> }
];

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: theme.spacing(2),
    marginRight: -theme.spacing(3)
  },
  container: {
    padding: theme.spacing(2),
    paddingRight: 0 // Only on large screen
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  itemSelected: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  itemBtn: {
    "&:hover": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    }
  },
  tabscontainer: {
    marginBottom: theme.spacing(2)
  },
  tab: {
    borderBottom: "1px solid #E6E6E6"
  }
}));

export default function ManageCompany({ companyId }) {
  if (!companyId) return <div />;

  const [company, setCompany] = useState();
  const [selectedItem, setSelectedItem] = useState(MENU[0].key);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (companyId) {
      AdminService.getCompany(companyId)
        .then(res => {
          const _company = res.content;
          setCompany(_company);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération de l'entreprise", {
            variant: "error"
          });
        });
    }
  }, [companyId]);

  const onPatch = updatedCompany => {
    setCompany(updatedCompany);
  };

  const handleMenuClick = key => {
    setSelectedItem(key);
  };

  if (!company) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4">
          ({company.status}) {company.name}
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Tabs
            value={selectedItem}
            onChange={(e, selectedTab) => handleMenuClick(selectedTab)}
            variant="fullWidth"
            className={classes.tabscontainer}
          >
            {MENU.map(item => (
              <Tab key={item.key} value={item.key} label={item.label} className={classes.tab} />
            ))}
          </Tabs>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9}>
        <div>
          {selectedItem === "INFORMATIONS" && <CompanyInformations company={company} />}
          {selectedItem === "CONFIGURATION" && (
            <Fragment>
              <CompanySignature company={company} onPatch={onPatch} />
              <CompanyDocument company={company} onPatch={onPatch} />
              <CompanyAssetsFileUpload company={company} />
              <CompanyGlobalConfiguration company={company} onPatch={onPatch} />
              <CompanyMailConfiguration company={company} onPatch={onPatch} />
              <CompanyFinancing company={company} onPatch={onPatch} />
              <CompanyAllowances company={company} onPatch={onPatch} />
              <CompanyMentions company={company} onPatch={onPatch} />
              <CompanyFeatures company={company} onPatch={onPatch} />
            </Fragment>
          )}
          {selectedItem === "USERS" && <CompanyUsers company={company} />}
        </div>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={3}>
          <List className={classes.list}>
            {MENU.map(item => (
              <ListItem
                disabled={item.disabled}
                button
                key={item.key}
                selected={selectedItem === item.key}
                onClick={() => handleMenuClick(item.key)}
                classes={{ selected: classes.itemSelected, button: classes.itemBtn }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Hidden>
    </Grid>
  );
}
